import axios from "axios";
import { useNavigate, Link, Navigate, Redirect  } from "react-router-dom";

const API_URL = "https://www.beauty.imetechno.com/eshopping-backend/";

const register = async (email, name, mobile, password) => {
  return axios.post(API_URL + "user/register.php", {
    email,
    name,
    mobile,
    password,
  })
    .then((response) => {
      return response.data;
    });
};

const login = async (userName, password) => {
  //alert(userName);
  return axios
    .post(API_URL + "user/login.php", {
      userName,
      password,
    })
    .then((response) => {
      //console.log(JSON.stringify(response));
      if (response.data.userData) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};


const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  API_URL,
}
export default AuthService;