import React, { useEffect, useState, useContext } from 'react';
import { Card, Form, Button, Alert, Container, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faCartShopping, faMagnifyingGlass, faEye, faHeartO } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink, useNavigate } from "react-router-dom";
import DataContext from '../helpers/MyContext';
import AuthService from '../services/Auth-service';

export default function ProductCard(props) {
    const { productData } = props;
    const { addToWatch, removeFromWatch } =useContext(DataContext);
    

    const [addcart, setaddcart] = useState(1);
    const [heart, setheart] = useState(false);
        
    const Heart=(filteredProduct)=>{
        if(heart){
            setheart(false);
            removeFromWatch(filteredProduct);
        }
        else{
            setheart(true);
            addToWatch(filteredProduct);
            }
        }

    const truncateDiscription = (str) => {
        return str.length > 10 ? str.substring(0, 50) + "..." : str;
    }

    const truncateTitle = (str) => {
        return str.length > 10 ? str.substring(0, 20) + "..." : str;
    }

    return (
        <>
                <Card className='h-100 card-i'>
                <Card.Img variant="top hover-zoom" src={AuthService.API_URL+productData.IMG_URL} />
                <Card.Body>
                    <Card.Subtitle className="card-sub-title mb-2 text-muted text-uppercase">Catogary</Card.Subtitle>
                    <Card.Title>{productData.PRDUCT_CODE}-{truncateTitle(productData.PRODUCT_NAME)}</Card.Title>
                    
                    <Card.Text className='card-sub-disc'>
                        {truncateDiscription(productData.SHORT_DISCRIPTION)}
                    </Card.Text>
                    <Card.Text>
                        <h4 className='me-3 text-danger danger' >$ {(Math.round(productData.PRICE * 100) / 100).toFixed(2)}</h4>
                        <small className='text-muted text-decoration-line-through'>$ {(Math.round((productData.PRICE*productData.DISCOUNT/100) * 100) / 100).toFixed(2)} </small><small className='text-muted'>-({productData.DISCOUNT} % off)</small>
                    </Card.Text>
                </Card.Body>
                <Card.Footer >
                    <Card.Link className='text-decoration-none' as={NavLink} to={`/product/${productData.PRDUCT_CODE}`}><FontAwesomeIcon className="text-primary mr-1 px-2" icon={faEye} /><small className="text-muted">View Detail</small></Card.Link>
                    <Button variant="link text-decoration-none float-end p-0 m-0" onClick={()=>Heart(productData.PRDUCT_CODE)} ><FontAwesomeIcon className={heart?"text-danger mr-1 px-2": "text-primary mr-1 px-2" } icon={faHeart} /></Button>
                </Card.Footer >
            </Card>
        </>
    )
}
