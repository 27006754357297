import axios from "axios";
import { useNavigate, Link, Navigate, Redirect  } from "react-router-dom";
import AuthService from "./Auth-service";
import authHeader from "./auth-header";

const getProductsList = async () =>{
    const response = await axios.get('https://jsonplaceholder.typicode.com/photos');
    return response.data;
}

const getAllCatogaryList = async () =>{
    const response = await axios.get(AuthService.API_URL + "category/get_category.php");
    return response.data;
}

const getSummeryCatogaryList = async () =>{
  const response = await axios.get(AuthService.API_URL + "category/summery.php");
  return response.data;
}

const getAllProductList = async () =>{
  const response = await axios.get(AuthService.API_URL + "product/get_product.php");
  return response.data;
}

const getAllBrandsList = async () =>{
  const response = await axios.get(AuthService.API_URL + "brands/get_brands.php");
  return response.data;
}

const postCategory = async (catName, imgUrl) =>{
    return axios.post(AuthService.API_URL + "category/add_category.php", {
        catName,
        imgUrl,
      })
        .then((response) => {
          return response.data;
        });
}

const postBrand = async (brandName, imgUrl) =>{
  return axios.post(AuthService.API_URL + "brands/add_brands.php", {
    brandName,
    imgUrl,
    })
      .then((response) => {
        return response.data;
      });
}

const deleteBrand = async (branCode) =>{
  return axios.post(AuthService.API_URL + "brands/brands_delete.php", {
    branCode,
    })
      .then((response) => {
        return response.data;
      });
}

const deleteProduct = async (productCode) =>{
  return axios.post(AuthService.API_URL + "product/product_delete.php", {
    productCode,
    })
      .then((response) => {
        return response.data;
      });
}

const deleteCategory = async (catCode) =>{
  return axios.post(AuthService.API_URL + "category/category_delete.php", {
    catCode,
    })
      .then((response) => {
        return response.data;
      });
}

const postProduct = async (productName, category, shortDesc, discription, brand, price, imgUrl) =>{
    return axios.post(AuthService.API_URL + "product/add_product.php", {
      productName,
      category,
      shortDesc,
      discription,
      brand,
      price,
      imgUrl,
      })
        .then((response) => {
          return response.data;
        });
}

const uploadCategoryImg = async (catSelectImgData)=> {
    const data = new FormData();
    data.append('file', catSelectImgData);
    return axios.post(AuthService.API_URL + "/category/upload.php", data, { // receive two parameter endpoint url ,form data 
    }).then((response)=>{
        return response.data;
    })
}

const uploadProductImg = async (catSelectImgData)=> {
    const data = new FormData();
    data.append('file', catSelectImgData);
    return axios.post(AuthService.API_URL + "/product/upload.php", data, { // receive two parameter endpoint url ,form data 
    }).then((response)=>{
        return response.data;
    })
}

const uploadBrandImg = async (catSelectImgData)=> {
  const data = new FormData();
  data.append('file', catSelectImgData);
  return axios.post(AuthService.API_URL + "/brands/upload.php", data, { // receive two parameter endpoint url ,form data 
  }).then((response)=>{
      return response.data;
  })
}

const DataService = {
    getProductsList,
    postCategory,
    uploadCategoryImg,
    getAllCatogaryList,
    getSummeryCatogaryList,
    uploadProductImg,
    postProduct,
    getAllProductList,
    uploadBrandImg,
    postBrand,
    getAllBrandsList,
    deleteBrand,
    deleteProduct,
    deleteCategory,

  }
  export default DataService;