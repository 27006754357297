import './App.css';
import { useEffect, useState} from 'react';
import { Route, Routes, useLocation} from "react-router-dom";
import LandingPage from './LandingPage';
import Dashbord from './dashbord/Dashbord';
import Login from './Login';
import Register from './Register';
import Header from './components/Header';
import Footer from './components/Footer';
import AuthService from './services/Auth-service';
import Product from './components/Product';
import ViewProduct from './components/ViewProduct';
import { DataProvider } from './helpers/MyContext';
import Category from './components/Category';
import Contact from './components/Contact';
import Basket from './components/Basket';
import Watch from './components/Watch';

function App() {

  const location = useLocation();

  const [user, setUser] = useState(null);
  const [isLogin, setLogin] = useState(false);
  const [isAdmin, setAdmin] = useState(false);
  const [isClient, setClient] = useState(false);

  const currentLogin = () => {
    const localUser = AuthService.getCurrentUser();
    if (localUser && localUser.userRole === '0' ) {
      setUser(localUser.userData);
      setLogin(true);
      setAdmin(true);
      setClient(false);
      //console.log(localUser.userRole);
    } else if(localUser){
      setUser(localUser.userData);
      setLogin(true);
      setAdmin(false);
      setClient(true);
      //console.log(localUser.userData);
    }else{
      setUser(null);
      setLogin(false);
      setAdmin(false);
      setClient(false);
      AuthService.logout();
    }

  }
  useEffect(() => {
    async function asyncCall() {
      currentLogin();
    }
    asyncCall();
  }, [location.pathname]);

  return (
    <>
    <DataProvider>
    <Header isLogin={isLogin} isAdmin={isAdmin} user={user} isClient={isClient} />
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/product" element={<Product />} />
        <Route exact path="/category" element={<Category />} />
        <Route exact path="/s-category/:id" element={<Category />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/basket" element={<Basket />} />
        <Route exact path="/watch" element={<Watch />} />
        <Route exact path="/product/:id" element={<ViewProduct />} />
        {isLogin && <Route exact path="/dash/*" element={<Dashbord isAdmin={isAdmin} isClient={isClient} />} />}
        {!isLogin &&<Route exact path="/login" element={<Login />} />}
        {!isLogin &&<Route exact path="/register" element={<Register />} />}
        <Route path="*" element={<p className='text-center m-5'>There's nothing here: 404!</p>} />
      </Routes>
      <Footer />
    </DataProvider>
      
      
    </>
  );
}

export default App;
