import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate, Route, Routes } from "react-router-dom";
import Admin from './admin/Admin';
import User from './user/User';
import CatItem from './admin/category/CatItem';
import ProductItem from './admin/product/ProductItem';
import AddProduct from './admin/product/AddProduct';
import Brands from './admin/brands/Brands';

export default function Dashbord(props) {
    const navigate = useNavigate();

    const { isAdmin, isClient } = props;

    return (
        <Container fluid className='py-4 px-xl-5'>
            {isAdmin ? (
                <Routes>
                    <Route path="/" element={<Admin />} />
                    <Route path="/profile" element={<Admin />} />
                    <Route path="/catitem" element={<CatItem />} />
                    <Route path="/brands" element={<Brands />} />
                    <Route path="/product" element={<ProductItem />} />
                    <Route path="/add-product" element={<AddProduct />} />
                </Routes>
            ) : (
                <Routes>
                    <Route path="/" element={<User />} />
                </Routes>
            )}
        </Container>
    )
}
