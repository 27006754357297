import React from 'react';
import { Card, Form, Button, Alert, Container, Spinner } from 'react-bootstrap';
import AuthService from '../services/Auth-service';

export default function CategoryCard(props) {
    const { categoryData } = props;
    return (
        <div>
            <Card className='h-100 card-i'>
                <Card.Body>
                    <Card.Title className='float-end text-muted'>Products ({categoryData.PRODUCT_COUNT})</Card.Title>
                </Card.Body>
                <Card.Img variant="top" src={AuthService.API_URL+ categoryData.IMG_URL} />
                <Card.Body>
                    <Card.Title>{categoryData.CATOGARY_NAME}</Card.Title>
                </Card.Body>
            </Card>
        </div>
    )
}
