import React from 'react';
import AuthService from '../services/Auth-service';

export default function BrandCard(props) {
    const brand = props;
    return (
        <div className="card rounded-0 m-1">
            <div className="card-body text-center">
                <img className="card-img" src={AuthService.API_URL+ brand.brand.IMG_URL} alt="" />
            </div>
        </div>
    )
}
